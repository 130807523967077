<template>
  <div class="homes">
    <!--    <el-row :gutter="20">-->
    <!--      <el-col :span="23">-->
    <!-- 查询 -->
    <el-form
      :inline="true"
      :model="params"
      class="demo-form-inline"
      style="margin-left: 6px; margin-top: -6px;" >
      <el-form-item label="登录账户：">
        <el-input
          v-model="params.loginName"
          placeholder="请输入登录账户"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="query()"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh" @click="reset()"
          >重置</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="userAddData"
          >新增</el-button
        >
      </el-form-item>
    </el-form>
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-row>
      <!-- 列表 -->
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column
              type="index"
              header-align="center"
              align="center"
              label="序号"
              width="60"
              :index="indexMethod"
            >
            </el-table-column>
            <el-table-column
              prop="loginName"
              header-align="center"
              align="center"
              label="登录账户"
            >
            </el-table-column>
            <el-table-column
              prop="realName"
              header-align="center"
              align="center"
              label="真实姓名"
            >
            </el-table-column>

            <el-table-column
              prop="tel"
              header-align="center"
              align="center"
              label="电话号码"
            >
            </el-table-column>
            <!--            <el-table-column-->
            <!--              prop="birthDate"-->
            <!--              header-align="center"-->
            <!--              align="center"-->
            <!--              label="出生日期"-->
            <!--              width="160"-->
            <!--            >-->
            <!--            </el-table-column>-->
            <el-table-column
              prop="createDatetime"
              header-align="center"
              align="center"
              label="创建时间"
            >
            </el-table-column>
            <!--            <el-table-column-->
            <!--              header-align="center"-->
            <!--              align="center"-->
            <!--              label="状态"-->
            <!--              width="70"-->
            <!--              prop="isUse"-->
            <!--            >-->
            <!--              <template slot-scope="scope">-->
            <!--                <el-switch-->
            <!--                  v-model="scope.row.isUse"-->
            <!--                  active-color="#13ce66"-->
            <!--                  active-value="Y"-->
            <!--                  inactive-value="N"-->
            <!--                  class="main-switch"-->
            <!--                  @change="derail(scope.row.id, scope.row.isUse)"-->
            <!--                  inactive-color="#ff4949"-->
            <!--                >-->
            <!--                </el-switch>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column
              label="操作"
              header-align="center"
              align="center"
              width="300"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-key"
                  @click="handleResetting(scope.row.id)"
                  >重置密码</el-button
                >
                <el-button
                  size="mini"
                  type="primary"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row.id)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!--    <el-pagination-->
    <!--        @current-change="handleCurrentChange"-->
    <!--        :current-page="pageInfo.page"-->
    <!--        :page-size="pageInfo.pageSize"-->
    <!--        layout="total, prev, pager, next, jumper"-->
    <!--        :total="recordsTotal"-->
    <!--        style="margin-top: 10px"-->
    <!--    >-->
    <!--    </el-pagination>-->
    <!--    <div style="margin-top: 10px; height: 32px; width: 100%"></div>-->
    <!-- 保存表单 -->
    <el-dialog
      :title="this.title"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="800px"
      @close="close('formName')"
    >
      <el-form :model="form" :rules="rules" ref="formName" label-width="100px">
        <el-form-item label="登录账户：" class="form" prop="loginName">
          <el-input
            v-model="form.loginName"
            placeholder="请输入登录账户"
            class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码：" class="form" prop="password">
          <el-input
            v-model="form.password"
            placeholder="请输入登录密码"
            class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名：" class="form" prop="realName">
          <el-input
            v-model="form.realName"
            placeholder="请输入真实姓名"
            class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话：" class="form" prop="tel">
          <el-input
            v-model="form.tel"
            placeholder="请输入电话"
            class="formInput"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px 0;
          "
        >
          <el-button type="primary" @click="formSave('formName')">
            确 定</el-button
          >
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="size"
      layout="total, prev, pager, next, jumper"
      :total="recordsTotal"
      style="margin-top: 10px"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  queryLists,
  sysAdminDetail,
  sysAdminDelete,
  sysAdminmodifyPassword,
  sysAdminSave,
  sysUserPage,
  updateSysUserById
} from '@/api/sysAdmin'

export default {
  name: 'sysAdmin',
  data() {
    //手机号验证
    return {
      switch: false, // 判断表单是新增还是编辑
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: '', // 图片显示的路径
      listAll: null, // 角色集合
      title: null, // 表单弹窗标题
      tableData: [], // 列表数据
      recordsTotal: null, // 列表总数
      dialogFormVisible: false, // 表单弹框快关
      dialogFormVisiblea: false, // 表单弹框快关
      dialogVisible: false,
      loginName: '',
      isUse: 0,

      folder: {
        folder: 'sysAdmin'
      },
      current: 1,
      size: 10,
      params: {
        loginName: null
      },
      options: [
        {
          label: '男'
        },
        {
          label: '女'
        }
      ],
      value: '',
      form: {
        // 表单数据
        id: null,
        loginName: null,
        tel: null,
        password: null,
        realName: null,
        createDatetime: null
      },
      rules: {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        loginName: [
          { required: true, message: '请输入登录账户', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入电话', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getList()
    // webServerHttpUrlString().then(res => {
    //   this.web = res
    // })
  },
  mounted() {},
  methods: {
    indexMethod(index) {
      // index = index + 1 + (this.page - 1) * this.pageInfo.pageSize
      index = index + 1 + (this.current - 1) * this.size
      return index
    },
    type(row) {
      if (this.queryEducationList != null) {
        for (let i = 0; i < this.queryEducationList.length; i++) {
          if (this.queryEducationList[i].id === row.codeId) {
            console.log(this.queryEducationList[i].value)
            return this.queryEducationList[i].value
          }
        }
      }
    },
    //清空表单
    formData() {
      this.form.id = null
      this.form.loginName = null
      this.form.tel = null
      this.form.password = null
      this.form.realName = null
    },
    // 查询
    query() {
      this.getList()
    },
    // 重置按钮
    reset() {
      this.params.loginName = null
      this.getList()
    },
    // 获取列表内容
    getList() {
      this.loading = true
      sysUserPage(this.current, this.size, this.params).then(res => {
        this.tableData = res.records
        this.recordsTotal = res.total
      })
    },

    //保存按钮
    formSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              console.log(this.form)
              if (this.form.id == null) {
                sysAdminSave(this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              } else {
                updateSysUserById(this.form).then(res => {
                  console.log(res)
                  this.$alert('保存成功！', '信息提示', {
                    confirmButtonText: '确定',
                    type: 'success'
                  }).then(() => {
                    this.getList()
                    this.formData()
                    this.dialogFormVisible = false
                    this.$refs[formName].resetFields()
                  })
                })
              }
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //新增
    userAddData() {
      this.switch = false
      this.imgList = []
      this.title = '新增'
      this.dialogFormVisible = true
      this.formData()
    },
    //状态
    derail(id, isUse) {
      console.log(isUse)
      console.log(id, isUse)
      let statusList = { id, isUse }
      sysAdminSave(statusList).then(() => {
        this.getList()
      })
    },
    handleResetting(id) {
      this.$confirm('您确定重置吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sysAdminmodifyPassword(id).then(res => {
            console.log(res)
            this.$alert('重置成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    //编辑
    async handleEdit(id) {
      this.switch = true
      this.imgList = []
      console.log('this.imgList', this.imgList)
      this.title = '编辑'
      await sysAdminDetail(id).then(res => {
        this.form = res
        console.log('res', res)
        this.form.id = res.id
        if (res.headPortraitPathHttpUrl !== this.web) {
          this.imgList.push({
            url: res.headPortraitPathHttpUrl
          })
        }
      })
      this.dialogFormVisible = true
    },
    // handeUplode(res,file,fileList){
    //   console.log()
    // },
    //删除
    handleDelete(id) {
      this.$confirm('您确定删除吗？', '信息提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          sysAdminDelete(id).then(res => {
            console.log(res)
            this.$alert('删除成功！', '信息提示', {
              confirmButtonText: '确定',
              type: 'success'
            }).then(() => {
              this.getList()
            })
          })
        })
        .catch(() => {})
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false
      // this.$refs[formName].resetFields()
    },
    // 清除表单验证
    close(formName) {
      this.$refs[formName].resetFields()
      // this.$refs.upload.clearFiles()
    },
    //  分页
    handleCurrentChange(val) {
      this.current = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.homes {
  margin: auto;
}
.form {
  width: 45%;
  display: inline-block;
  margin: 10px 10px;
}
/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
.el-upload-list__item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 108px !important;
  height: 108px !important;
  margin: 0 8px 8px 0;
  display: inline-block;
}
.el-upload-list__item-thumbnail {
  width: 104px !important;
  height: 104px !important;
}
.el-form-item__content {
  height: 90%;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 20px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
.formInput {
  width: 90%;
  margin-left: 16px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 10px 10px;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
/deep/.el-form-item__error {
  margin-left: 16px;
}
</style>
