import {get,post} from "../http/index";

// 列表分页
// export const sysUserPage = (params) => post('/sysUser/page', params);


//只穿page的分页接口
export const sysUserQueryList = (page) => get('/sysUser/queryList/' + page);
export const sysUserPage = (current, size, params) => post('/sysUser/page?current=' + current + '&size=' + size, params);


// 删除
export const sysUserDeletById = (params) => post('/sysUser/deletById?id=' + params);

// 详情
export const sysAdminDetail = (params) => get('/sysUser/detail/' + params);

// 新增保存
export const sysAdminSave = (params) => post('/sysUser/save', params);
//修改
export const updateSysUserById = (params) => post('/sysUser/updateSysUserById', params);

// 集合
export const queryLists = () => get('/sysAdmin/queryList');

export const sysAdminUplaod = () => get('/fileProcessing/upload');

export const sysAdminmodifyPassword = (params) => get('/sysUser/reset/' + params)

export const checkLoginNameIsExist = (params) => get('/sysAdmin/checkLoginNameIsExist?loginName=' + params)

// 角色集合集合
// export const listAll = () => get('sysRole/listAll');

// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrlString = () => get('/fileProcessing/webServerHttpUrl')

// 分页
export const sysAdminPage = (params) => post('/sysAdmin/page', params)
